import React from "react"
import { Link } from "gatsby"

import SEO from "../components/seo"
import logo from "../images/logo-lp-bootcamp.png"
import Footer from "../components/footer"

const Bootcamp = () => (
  <>
    <SEO title="Contact Us" />
    <main className="bootcamp-innovation">
      <header id="bootcamp-header">
        <nav className="navbar">
          <div className="container">
            <div className="navbar-header">
              <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar">
                <span className="sr-only">Toggle navigation</span>
                <span className="fa fa-bars"></span>
              </button>
              <Link className="navbar-brand" to="/"><img src={logo} alt="Crafters Innovation Studio" /></Link>
            </div>
            <div id="navbar" className="collapse navbar-collapse">
              <ul className="nav navbar-nav navbar-right">
                <li><a href="#bootcamp-form">Inscreva-se</a></li>
              </ul>
            </div>
          </div>
        </nav>
      </header>

      <div id="bootcamp-top">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-offset-3 col-md-6">
              <h1>Innovation Experience</h1>
              <p>Um programa para empreendedores ávidos por inovação.</p>
              <a href="#bootcamp-form" className="btn btn-lg">Inscreva-se grátis</a>
            </div>
          </div>
        </div>
      </div>

      <div id="bootcamp-themes">
        <div className="container">
          <ul className="row">
            <li className="col-xs-12 col-sm-4">Business<br />Model Canvas</li>
            <li className="col-xs-12 col-sm-4">Value<br />proposition design</li>
            <li className="col-xs-12 col-sm-4">Ten types<br />of innovation</li>
            <li className="col-xs-12 col-sm-4">Circular design</li>
            <li className="col-xs-12 col-sm-4">Antifragilidade</li>
            <li className="col-xs-12 col-sm-4">Lean metrics</li>
            <li className="col-xs-12 col-sm-4">Cynefin</li>
            <li className="col-xs-12 col-sm-4">Design thinking</li>
            <li className="col-xs-12 col-sm-4">Fit for purpose</li>
          </ul>
        </div>
      </div>

      <div id="bootcamp-form">
        <div className="container">
          {/* <p>Envie um email para <a id="contact-email" href="mailto:bootcamp@crafters.cc">bootcamp@crafters.cc</a> com o título "Quero participar do bootcamp" e retornaremos informando os detalhes do primeiro webinar.</p> */}
          <p>Não há turmas abertas neste momento. Caso queira um orçamento para realização inCompany do bootcamp, envie um email para <a id="contact-email" href="mailto:bootcamp@crafters.cc">bootcamp@crafters.cc</a>.</p>
        </div>
      </div>

      <Footer />
    </main>
  </>
)

export default Bootcamp
